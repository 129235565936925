<script setup lang="ts">
import Logo from "./Logo.vue";
import axios from "axios";
import {useRouter} from "vue-router";
import {useUserStore} from "../stores/UserStore";
import {ref} from "vue";
import {User} from "../types";
import {useI18n} from "vue-i18n";


const {t} = useI18n();
const userStore = useUserStore();
const router = useRouter();

const showErrorMessage = ref(false);

let email: string|null = null;
let password: string|null = null;

const onSubmit = async (event: Event) => {
    showErrorMessage.value = false;

    await axios.get('/sanctum/csrf-cookie');

    try {
        const loginResponse = await axios.post('/login', {
            email: email,
            password: password,
        });

        if (loginResponse.data.two_factor) {
            console.error('Unsupported authentication method')
        }

        const userResponse = await axios.get('/api/user');
        const defaultUserData = {
            integration: {
                rounding_type: 'percentage',
            }
        }

        userStore.login(userResponse.data as User);

        await router.push({name: 'dashboard.catalog'});
    } catch (error) {
        showErrorMessage.value = true;
    }
}



</script>

<template>
    <section>
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white h-16">
                <Logo></Logo>
            </a>
            <div class="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 class="text-xl font-bold leading-tight tracking-tight md:text-2xl dark:text-white">
                        Dashboard
                    </h1>
                    <form class="space-y-4 md:space-y-6" @submit.prevent="onSubmit">
                        <div>
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ t('login.labels.email') }}</label>
                            <input v-model="email" autocomplete="username" type="email" name="email" id="email" class="focus:outline-none border sm:text-sm rounded-lg  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="name@company.com" required>
                        </div>
                        <div>
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ t('login.labels.password') }}</label>
                            <input v-model="password" autocomplete="current-password" type="password" name="password" id="password" placeholder="••••••••" class="focus:outline-none border sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" required>
                        </div>

                        <div class="flex text-sm text-red-800 dark:text-red-400" role="alert" v-if="showErrorMessage">
                            <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                            <div>
                                {{ t('login.errors.invalidCredentials') }}
                            </div>
                        </div>

                        <button type="submit" class="w-full text-white focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700">{{ t('login.enter') }}</button>
                        <p class="text-sm font-light dark:text-gray-400">
                            {{ t('login.noAccount') }} <a href="mailto:support@recommerceiq.com" target="_blank" class="font-medium hover:underline">{{ t('login.contactUs') }}</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
