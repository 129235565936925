<script setup lang="ts">

import {ref, Ref} from "vue";
import {CompetitorItemDetail, HistoricalData, Item, PriceResponse} from "../types";
import axios from "axios";
import Flag from "./Flag.vue";
import {forEach} from "lodash";
import dayjs from "dayjs";
import {Chart, ChartConfiguration} from "chart.js/auto";
import {useI18n} from "vue-i18n";

const props = defineProps<{
    id: number,
    itemId: number,
}>();

const {t} = useI18n();
const chart = ref();
const detail: Ref<CompetitorItemDetail|null> = ref(null);
const items: Ref<Array<Item>> = ref([]);
const historical: Ref<Array<HistoricalData>> = ref([]);

const loadItem = async (id: number, itemId: number) => {
    try {
        const itemResponse = await axios.get('/api/competitors/' + id + '/items/' + itemId);
        detail.value = itemResponse.data.detail as CompetitorItemDetail;
        items.value = itemResponse.data.items;
        historical.value = itemResponse.data.historical;
        // showMinPriceReachedAlert = shouldShowMinPriceReachedAlert();
        // showMaxPriceReachedAlert = shouldShowMaxPriceReachedAlert();
        //
        await renderChart(historical.value, detail.value.vendor.name);
    } catch (error) {
        console.error(error)
    }
}

const renderChart = async (data: Array<HistoricalData>, vendorName: string) => {
    const datasets: Array<{
        label: string;
        data: { x: number; y: number; }[],
        pointRadius?: number,
        hidden?: boolean
    }> = [];

    const medianPrices: { x: number; y: number; }[] = [];
    const avgPrices: { x: number; y: number; }[] = [];
    const minPrices: { x: number; y: number; }[] = [];
    const maxPrices: { x: number; y: number; }[] = [];
    forEach(data, (price) => {
        medianPrices.push({
            x: dayjs(price.date).unix(),
            y: price.median_price,
        });

        avgPrices.push({
            x: dayjs(price.date).unix(),
            y: price.average_price,
        });

        minPrices.push({
            x: dayjs(price.date).unix(),
            y: price.min_price,
        });

        maxPrices.push({
            x: dayjs(price.date).unix(),
            y: price.max_price,
        });
    });

    datasets.push({
        label: 'Min',
        data: minPrices,
    });

    datasets.push({
        label: 'Median',
        data: medianPrices,
        hidden: true,
    });

    datasets.push({
        label: 'Max',
        data: maxPrices,
        hidden: true,
    });

    datasets.push({
        label: 'Avg',
        data: avgPrices,
        hidden: true,
    });

    //Chart.defaults.datasets.scatter.showLine = false;

    // Chart configuration
    const config: ChartConfiguration = {
        type: 'scatter',
        data: {
            datasets: datasets,
        },
        options: {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                    title: {
                        color: 'rgba(156, 163, 175, 1)',
                    }
                },
                tooltip: {
                    callbacks: {
                        title: (items) => items[0].dataset.label + " " + dayjs.unix(Number(items[0].parsed.x)).format('DD/MM'),
                        label: (items) => '€ ' + items.parsed.y,
                    }
                }
            },
            scales: {
                y: {
                    grid: {
                        color: 'rgba(255, 255, 255, 0.1)'
                    },
                    ticks: {
                        callback: (value, index, ticks) => '€ ' + value,
                        color: 'rgba(156, 163, 175, 1)',
                    },
                },
                x: {
                    grid: {
                        color: 'rgba(255, 255, 255, 0.1)'
                    },
                    ticks: {
                        callback: (value, index, ticks) => dayjs.unix(Number(value)).format('DD/MM'),
                        color: 'rgba(156, 163, 175, 1)',
                    }
                },
            },
        },
    };

    new Chart(
        chart.value,
        config
    );
}

loadItem(props.id, props.itemId);

</script>

<template>
    <router-link :to="{ name: 'dashboard.competitors.detail', params: { id: props.id }}"  class="mb-4 inline-flex items-center font-medium hover:text-gray-400 text-gray-500 transition-colors">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-1">
            <path fill-rule="evenodd"  d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z" clip-rule="evenodd"/>
        </svg>
        {{ t('competitor.details.backToList') }}
    </router-link>

    <h2 class="text-xl font-bold dark:text-white" v-if="detail">
        {{ detail.name }}

        <a :href="detail.url" target="_blank" class="inline-flex items-center ml-3 px-3 py-2 text-xs font-medium text-center text-white rounded-lg dark:bg-blue-600 dark:hover:bg-blue-700" v-if="detail.url">

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 mr-2">
                <path fill-rule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clip-rule="evenodd" />
                <path fill-rule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clip-rule="evenodd" />
            </svg>

            {{ t('catalog.details.open') }}
        </a>
    </h2>

    <div class="flex mb-8 text-gray-400 text-xs mt-2 space-x-6 divide-x divide-slate-600" v-if="detail">
        <div>
            <p class="text-gray-200 font-bold text-2xl">{{ detail.price }} {{detail.currency_symbol}}</p>
        </div>

        <div class="pl-2">
            <span class="capitalize">{{ t('common.grade') }}</span>
            <p class="text-gray-200 font-bold">{{ detail.grade }}</p>
        </div>

        <div class="pl-2" v-for="(value, name) in detail.details ">
            <span class="capitalize">{{ name }}</span>
            <p class="text-gray-200 font-bold uppercase">{{ value }}</p>
        </div>
    </div>

    <div class="flex flex-col lg:w-1/2 bg-gray-800 sm:rounded-lg p-4">
        <h3 class="text-white text-xl font-medium mb-4">{{ t('catalog.details.pricesHistorical') }}</h3>
        <canvas ref="chart"/>
    </div>

    <div class="rounded-lg mt-12" v-if="items.length">
        <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div class="overflow-x-auto">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead
                        class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.color') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.battery') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.price') }}</th>
                        <th scope="col" class="px-2 py-3">{{ t('catalog.details.table.headings.country') }}</th>
                        <th scope="col" class="px-2 py-3">Competitor</th>
                        <th scope="col" class="px-2 py-3">Review</th>
                        <th scope="col" class="px-2 py-3">Link</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(item, index) in items"
                        class="dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 border-b">
                        <td class="px-2 py-3 capitalize">
                            <span>{{ item.color ?? '-' }}</span>
                        </td>

                        <td class="px-2 py-3 capitalize">
                            <span>{{ item.battery ?? 'Standard' }}</span>
                        </td>

                        <td class="px-2 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <div class="flex items-center">{{ item.currency_symbol }} {{ item.price }}</div>
                        </td>

                        <td class="px-2 py-3 text-xs capitalize">
                            <Flag :country="item.vendor.country" :show="true" :size="10" />
                        </td>

                        <td class="px-2 py-3 text-xs">
                            <div class="text-xs text-gray-300">{{ item.seller_name }}</div>
                        </td>

                        <td class="px-2 py-3">
                            <div class="text-xs font-medium flex items-center" v-if="item.vendor.reviews_url !== null">
                                <svg aria-hidden="true" class="w-3 h-3 text-yellow-400" fill="currentColor"
                                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                </svg>

                                <p class="ml-2 text-sm"><a class="underline hover:text-white transition-colors" :href="item.vendor.reviews_url" target="_blank">{{ item.vendor.rating }} su 5</a></p>
                            </div>
                        </td>

                        <td class="px-2 py-3">
                            <a :href="item.url" target="_blank" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">{{ t('catalog.details.table.open') }}</a>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
