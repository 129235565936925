<script setup lang="ts">
import {useI18n} from "vue-i18n";
import Logo from './Logo.vue';
import LogoFull from "./LogoFull.vue";
import {useUserStore} from "../stores/UserStore";

import {Ref, ref} from 'vue';
import axios from "axios";
import {useRouter} from "vue-router";
import Flag from "./Flag.vue";

const showSidebar = ref(false);
const showSettings = ref(false);
const userStore = useUserStore();
const router = useRouter();
const {t} = useI18n();

type NotificationResponse = {
    notifications: number
}

let data: Ref<NotificationResponse | null> = ref( null);

const closeSettings = () => showSettings.value = false;

const logout = async  (event: MouseEvent) => {
    await axios.delete('/logout');

    userStore.logout();

    await router.push({name: 'login'});
}

const loadNotifications = async () => {
    try {
        const notificationResponse = await axios.get('/api/user/notifications');
        data.value = notificationResponse.data;
    } catch (error) {

    }
}

loadNotifications();

</script>

<template>
    <header class="dark:bg-gray-800 h-16 fixed top-0 left-0 w-full dark:border-gray-700 border-b block z-30">
        <div class="flex flex-wrap justify-between items-center h-full px-4">

            <button @click="showSidebar = !showSidebar" type="button" class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700">
                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <div class="flex justify-start items-center">
                <!-- TODO: avoid duplicating <a> -->
                <a href="#" class="inline-flex h-10 md:hidden">
                    <Logo></Logo>
                </a>

                <a href="#" class="hidden h-8 md:inline-flex">
                    <LogoFull></LogoFull>
                </a>
            </div>

            <div v-if="userStore.profile" class="flex items-center lg:order-2 font-medium dark:text-white">
                <div class="relative" @click="showSettings = true" v-click-outside="closeSettings">
                    <button type="button" class="focus:outline-none">{{ userStore.configuration?.store_name || userStore.profile.name }}</button>

                    <transition
                        enter-from-class="-translate-y-6 opacity-0 scale-75"
                        enter-to-class="translate-y-0 opacity-100 scale-100"
                        leave-from-class="translate-y-0 opacity-100 scale-100"
                        leave-to-class="-translate-y-6 opacity-0 scale-75" >
                        <div v-if="showSettings" class="transition-all duration-75 ease-in-out absolute z-100 right-0 top-8 w-56 text-base list-none rounded shadow dark:bg-gray-700 divide-y dark:divide-gray-600">
                            <div class="py-3 px-4">
                                <span class="block text-sm font-semibold text-gray-900 dark:text-white">{{ userStore.profile.name }}</span>
                                <span class="block text-sm font-light text-gray-500 truncate dark:text-gray-400">{{ userStore.profile.email }}</span>
                            </div>

                            <ul class="py-1 font-light text-gray-500 dark:text-gray-400" aria-labelledby="dropdown">
                                <li>
                                    <a href="#"  @click.prevent="logout" class="w-full block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                        {{ t('header.logout') }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </transition>
                </div>
            </div>
        </div>



    </header>


    <aside id="sidebar" class="w-[210px] z-20 fixed top-16 left-0 dark:bg-gray-800 dark:border-gray-700 border-r px-3 pb-4 pt-4 overflow-y-auto transition-transform sm:translate-x-0" :class="[showSidebar ? 'translate-x-0' : '-translate-x-full']">
        <div class="h-full flex flex-col justify-between">
            <ul class="space-y-2 shrink-0 ">

                <li v-if="userStore.configuration?.show_catalog">
                    <router-link to="/dashboard/catalog" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z"/>
                            <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z"/>
                        </svg>

                        <div class="ml-3 flex items-center flex-1">
                            <span class="flex-1">{{ t('sidebar.catalog') }}</span>
                            <div class="flex items-center justify-center text-yellow-400 relative"  v-if="data?.notifications !== undefined && data?.notifications > 0 ">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6">
                                  <path d="M4.214 3.227a.75.75 0 00-1.156-.956 8.97 8.97 0 00-1.856 3.826.75.75 0 001.466.316 7.47 7.47 0 011.546-3.186zM16.942 2.271a.75.75 0 00-1.157.956 7.47 7.47 0 011.547 3.186.75.75 0 001.466-.316 8.971 8.971 0 00-1.856-3.826z" />
                                  <path fill-rule="evenodd" d="M10 2a6 6 0 00-6 6c0 1.887-.454 3.665-1.257 5.234a.75.75 0 00.515 1.076 32.94 32.94 0 003.256.508 3.5 3.5 0 006.972 0 32.933 32.933 0 003.256-.508.75.75 0 00.515-1.076A11.448 11.448 0 0116 8a6 6 0 00-6-6zm0 14.5a2 2 0 01-1.95-1.557 33.54 33.54 0 003.9 0A2 2 0 0110 16.5z" clip-rule="evenodd" />
                                </svg>

                                <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-700 border-2 border-gray-800 rounded-full -top-3 -right-3">
                                    {{ data?.notifications }}
                                </div>
                            </div>
                        </div>
                    </router-link>
                </li>

                <li v-if="userStore.configuration?.show_catalog">
                    <router-link to="/dashboard/log" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                            <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z" />
                        </svg>


                        <span class="ml-3">{{ t("sidebar.log") }}</span>
                    </router-link>
                </li>

                <li>
                    <span class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                            <path fill-rule="evenodd" d="M2.25 13.5a8.25 8.25 0 018.25-8.25.75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z" clip-rule="evenodd"/>
                            <path fill-rule="evenodd" d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z" clip-rule="evenodd"/>
                        </svg>

                        <span class="ml-3 flex-1">{{ t("sidebar.prices.title") }}</span>
                    </span>

                    <ul class="py-2" >
                        <li v-for="(category, index) in userStore.profile.categories" v-if="userStore.profile">
                           <router-link :to="{ name: 'dashboard.prices.category', params: { id: category.id }}" href="" class="flex items-center w-full py-1 text-xs font-normal text-gray-900 transition duration-75 rounded-lg pl-6 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">

                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-2">
                                   <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                               </svg>


                               <span>{{ t(`sidebar.prices.${category.name.toLowerCase()}`) }}</span>

                           </router-link>
                        </li>
                    </ul>
                </li>


                <li>
                    <span class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                            <path fill-rule="evenodd" d="M12 1.5a.75.75 0 01.75.75V4.5a.75.75 0 01-1.5 0V2.25A.75.75 0 0112 1.5zM5.636 4.136a.75.75 0 011.06 0l1.592 1.591a.75.75 0 01-1.061 1.06l-1.591-1.59a.75.75 0 010-1.061zm12.728 0a.75.75 0 010 1.06l-1.591 1.592a.75.75 0 01-1.06-1.061l1.59-1.591a.75.75 0 011.061 0zm-6.816 4.496a.75.75 0 01.82.311l5.228 7.917a.75.75 0 01-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 01-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 01-1.247-.606l.569-9.47a.75.75 0 01.554-.68zM3 10.5a.75.75 0 01.75-.75H6a.75.75 0 010 1.5H3.75A.75.75 0 013 10.5zm14.25 0a.75.75 0 01.75-.75h2.25a.75.75 0 010 1.5H18a.75.75 0 01-.75-.75zm-8.962 3.712a.75.75 0 010 1.061l-1.591 1.591a.75.75 0 11-1.061-1.06l1.591-1.592a.75.75 0 011.06 0z" clip-rule="evenodd"/>
                        </svg>

                        <span class="ml-3 flex-1">{{ t("sidebar.strategies.title") }}</span>
                    </span>

                    <ul class="py-2">
                        <li v-if="userStore.extra?.resell_enabled">
                            <router-link :to="{ name: 'dashboard.strategies.resell'}" href="" class="flex items-center w-full py-1 text-xs font-normal text-gray-900 transition duration-75 rounded-lg pl-6 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-2">
                                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                </svg>


                                <span>{{ t("sidebar.strategies.resell") }}</span>

                            </router-link>
                        </li>

                        <li v-if="userStore.extra?.buyback_enabled">
                            <router-link :to="{ name: 'dashboard.strategies.buyback'}" href="" class="flex items-center w-full py-1 text-xs font-normal text-gray-900 transition duration-75 rounded-lg pl-6 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-2">
                                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                </svg>


                                <span>{{ t("sidebar.strategies.buyback") }}</span>

                            </router-link>
                        </li>
                    </ul>
                </li>


                <li v-if="userStore.profile?.vendors && userStore.profile?.vendors.length > 0">
                    <span class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white">

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                          <path fill-rule="evenodd" d="M1 2.75A.75.75 0 0 1 1.75 2h16.5a.75.75 0 0 1 0 1.5H18v8.75A2.75 2.75 0 0 1 15.25 15h-1.072l.798 3.06a.75.75 0 0 1-1.452.38L13.41 18H6.59l-.114.44a.75.75 0 0 1-1.452-.38L5.823 15H4.75A2.75 2.75 0 0 1 2 12.25V3.5h-.25A.75.75 0 0 1 1 2.75ZM7.373 15l-.391 1.5h6.037l-.392-1.5H7.373ZM13.25 5a.75.75 0 0 1 .75.75v5.5a.75.75 0 0 1-1.5 0v-5.5a.75.75 0 0 1 .75-.75Zm-6.5 4a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5A.75.75 0 0 1 6.75 9Zm4-1.25a.75.75 0 0 0-1.5 0v3.5a.75.75 0 0 0 1.5 0v-3.5Z" clip-rule="evenodd" />
                        </svg>

                        <span class="ml-3 flex-1">{{ t("sidebar.competitors") }}</span>
                    </span>

                    <ul class="py-2" >
                        <li v-for="(vendor, index) in userStore.profile?.vendors">
                            <router-link :to="{ name: 'dashboard.competitors.detail', params: { id: vendor.id }}" href="" class="flex items-center w-full py-1 text-xs font-normal text-gray-900 transition duration-75 rounded-lg pl-6 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-2">
                                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                </svg>


                                <span class="mr-2">{{ vendor.name }}</span>

                                <Flag :country="vendor.country" :show="true" :size="10" />

                            </router-link>
                        </li>
                    </ul>
                </li>

            </ul>

            <a href="mailto:support@recommerceiq.com" class="text-sm inline-flex items-center font-medium text-blue-600 dark:text-gray-500 hover:dark:text-white transition-all">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-2">
                    <path d="M8.25 4.5a3.75 3.75 0 117.5 0v8.25a3.75 3.75 0 11-7.5 0V4.5z" />
                    <path d="M6 10.5a.75.75 0 01.75.75v1.5a5.25 5.25 0 1010.5 0v-1.5a.75.75 0 011.5 0v1.5a6.751 6.751 0 01-6 6.709v2.291h3a.75.75 0 010 1.5h-7.5a.75.75 0 010-1.5h3v-2.291a6.751 6.751 0 01-6-6.709v-1.5A.75.75 0 016 10.5z" />
                </svg>

                {{ t("sidebar.support") }}
            </a>
        </div>

    </aside>

    <div id="container" class="flex p-6 pt-24 sm:px-12 sm:ml-56">
        <main class="w-full">
            <router-view />
        </main>
    </div>

</template>
